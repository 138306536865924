.widgetsTech {
    width: 80%;
    margin: 0 auto;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-evenly;
    i {
        font-size: 50px;
    }
}

@media only screen and (max-width: 480px) {
    .widgetsTech {
        justify-content: space-around;
        column-gap: 20px;
        i {
            font-size: 40px;
        }
    }
}