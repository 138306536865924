.modalidadContainer {
    width: 90%;
    margin: 0 auto;
    text-align: center;
    .modalidadBtns {
        margin-top: 50px;
        display: flex;
        justify-content: space-evenly;
    }
    .textoModalidad {
        #subscription {
            ul {
                margin: 20 0 0 0;
                padding: 0;
                list-style-position: inside;
            }
        }
    }
}