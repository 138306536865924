.qrListContainer {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    row-gap: 100px;
    .list {
        margin: 50 0 0 0;
        padding: 0;
        display: flex;
        flex-direction: row;
        justify-content: space-evenly;
        align-items: center;
        flex-wrap: wrap;
        list-style: none;
        li {
            margin-top: 15px;
            cursor: pointer;
            border-radius: 5px;
            padding: 5px;
            &:hover {
              background-color: $colorAzul
            }
          }
        .active {
            background-color: $colorAzul;
        }
    }
    div {
        text-align: center;
    }
    .texto_paginas_list {
        text-align: center;
        margin: auto;
        width: 30%;
        border: 1px dashed $colorAzul;
        background-color: rgba($color: $colorAzul, $alpha: 0.1);
        padding: 10px;
      }
}

@media only screen and (max-width: 480px) {
    .qrListContainer {
      margin-top: 50px;
      row-gap: 50px;
      .texto_paginas_list {
        width: 80%;
      }
    }

    .list {
      text-align: center;
      margin: 0;
      padding: 0;
    }
  }