.frameListContainer {
    margin-top: 50px;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    row-gap: 100px;
    .texto_paginas_list {
      text-align: center;
      margin: auto;
      width: 30%;
      border: 1px dashed $colorAzul;
      background-color: rgba($color: $colorAzul, $alpha: 0.1);
      padding: 10px;
    }
    div {
      text-align: center;
    }
}

.list {
    list-style: none;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-evenly;
    align-items: center;
    column-gap: 20px;
    li {
        margin-top: 15px;
        cursor: pointer;
        border-radius: 5px;
        padding: 5px;
        &:hover {
          background-color: $colorAzul
        }
      }
    .active {
        background-color: $colorAzul;
    }
  }

  @media only screen and (max-width: 480px) {
    .frameListContainer {
      margin-top: 50px;
      row-gap: 50px;
      .texto_paginas_list {
        width: 80%;
      }
    }

    .list {
      text-align: center;
      margin: 0;
      padding: 0;
    }

  }

