#widgetsTechnologies {
    margin-top: 150px;
    margin-bottom: 100px;
    padding-top: 50px;
  }

  #paginasWeb {
    margin-top: 100px;
  }

  #qrs {
    margin-top: 100px;
  }

  #sobreNosotros {
    margin-top: 100px;
  }

  #opcionesDePago {
    margin-top: 100px;
  }