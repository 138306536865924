.widgetArrowUp {
    z-index: 90;
    margin-right: 50px;
    display: flex;
    flex-direction: row-reverse;
    position: sticky;
    top: 90%;
    i {
        font-size: 42px;
        color: $colorAzul;
    }
}

@media only screen and (max-width: 480px) {
    .widgetArrowUp {
        margin-right: 20px;
        i {
            font-size: 30px;
        }
    }
}