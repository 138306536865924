body {
    margin: 0;
    padding: 0;
    color: $colorBlanco;
    font-family: 'Open sans', sans-serif;
    font-size: 18px;
    overflow-x: hidden;
  }
  
  html {
    background-color: $colorNegro;
    scroll-behavior: smooth;
  }
  
  @media only screen and (max-width: 480px) {
    body {
      font-size: 16px;
    }

    main {
      margin-top: 50px;
      margin-bottom: 100px;
    }
  }

  
