.form_container {
    width: 30%;
    form {
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        .recaptcha {
            margin-bottom: 20px;
        }
        input {
            height: 30px;
            width: 100%;
            line-height: 30px;
            font-size: 18px;
            border-radius: 5px;
            margin-top: 10px;
            margin-bottom: 10px;
        }
        textarea {
            width: 100%;
            height: 100px;
            margin-top: 10px;
            margin-bottom: 20px;
        }
    }
}


@media only screen and (max-width: 480px) {
    .form_container {
        width: 90%;
    }
}