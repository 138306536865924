.frames {
    iframe {
        width: calc(100vw - 300px);
        height: 700px;
        &:nth-child(4) {
            background-color: #fff;
        }

        &:last-child {
            background-color: #fff;
        }
    }
}

@media only screen and (max-width: 480px) {

    .frames {
        iframe {
            width: calc(90vw);
            height: 300px;
        }
    }
}