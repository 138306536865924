.footer {
    z-index: 100;
    margin-top: 80px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    .socialMedia {
        width: 40%;
        display: flex;
        flex-direction: row;
        justify-content: space-evenly;
        align-items: center;
        a {
            z-index: 101;
            color: $colorBlanco;
            &:hover {
                color: $colorAzul;
            }
            i {
                font-size: 50px;
            }
        }
    }
}

@media only screen and (max-width: 480px) {
    .footer {
        .socialMedia {
            width: 90%;
            a {
                i {
                    font-size: 40px;
                }
            }
        }
    }
}